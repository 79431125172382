@use '../utility/reuseble' as *;
@use '../utility' as *;
.box_main {
  padding: 20px;
}
.slot-box {
  padding: 10px 5px;
  border: 1px solid;
  border-radius: 10px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  background-color: white;
}
.slot-box:hover {
  background-color: #efaecb;
  color: white;
  border-color: #efaecb;
}
.slot-box  p {
  margin-bottom: 0 !important;
}

.slot-section {
  display: flex;
  flex-wrap: wrap;
}
.date-custom {
  width: 20%;
  margin: 10px;
  border-radius: 10px;
}

.width-100 {
  width: 100%;
}

.available-text {
  margin-top: 10px;
  margin-left: 10px;
}

.slot-submit {
  border-radius: 10px;
  margin-left: 10px;
}

.submit-section {
  display: flex;
  justify-content: center;
}

.selectedSlot {
  background-color: #efaecb;
  color: white;
}

.day-label {
  margin: 20px;
}

.time-field {
  width: auto;
  margin: 20px;
}

.join_meeting_btn {
  background-color:#088fff ;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
}

.internal_notes_table th{
  background-color: whitesmoke;
  color: black;
}

.internal_notes_table td{
  text-align: center;
}

.notes_order_number {
  width: 20%;
}

.notes_order_internal_notes {
  width: 60%;
}

.submitDisabled {
  pointer-events: none;
}

.call_schedule_note {
  color: red;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.email_dropdown {
  width: 500px !important;
}